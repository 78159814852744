import React from 'react'

function NoDataAvailable() {
    return (
        <>
            <p className='no-data-available my-3'>No data available</p>
        </>
    )
}

export default NoDataAvailable
