import React from 'react'

function LoaderComman() {
    return (
        <>
            <div className='loader-main d-flex justify-content-center align-items-center w-100 h-100'>
                <div className="loader"></div>
            </div>
        </>
    )
}

export default LoaderComman